import type { Draft } from 'immer';
import type {
  LeistungRequest,
  LeistungRequestWithRegschl,
  OrgRequest,
} from '../action/detail/detailTypes';
import type { Dropdown } from '../action/search/searchCommon';
import { Dropdowns } from '../action/search/searchCommon';
import type {
  CitySearchRequest,
  Gebiet,
  RegschlSearchRequest,
  SearchMoreRequest,
  SearchRequest,
  SearchResult,
  SearchSuggestion,
  SearchSuggestionRequest,
  SelectedOrt,
  StateSearchRequestType,
} from '../action/search/searchTypes';
import { SELECTED_ORT_HESSEN } from '../constants';
import type { Flags } from '../history/flags';
import { flagsToObject } from '../history/flags';
import { Screen } from '../view';
import type {
  PageFrame,
  HtmlPatchBereich,
  HtmlPatchInformation,
  HtmlPatchLage,
  HtmlPatchLeistung,
  HtmlPatchOrg,
  HtmlPatchOrgSuche,
  HtmlPatchStartseite,
  HtmlPatchSublage,
  HtmlPatchSuche,
} from '../action/htmlTypes';
import type { InformationRequest } from '../action/detail/InformationRequest';
import type {
  ScreenRequestBereich,
  ScreenRequestInformation,
  ScreenRequestLage,
  ScreenRequestLeistung,
  ScreenRequestOrg,
  ScreenRequestOrgSuche,
  ScreenRequestStartseite,
  ScreenRequestSublage,
  ScreenRequestSuche,
} from '../action/common/commonRequestTypes';
import type { MapData } from '../map_online_services/data/mapDataType';
import type { MapOnlineCountsResult } from '../map_online_services/mapEvents';
import type {
  BereichRequest,
  LagenRequest,
  SublagenRequest,
} from '../action/filter/PvLagenRequest';
import {
  getChatbotPrivacyAccepted,
  getNfkPrivacyAccepted,
} from './sessionStore';

type UndefOrError = undefined | Error;
type UndefOr<T> = undefined | T;
export type AbortRequestFor<T> = {
  abortRequest: number;
  forRequest: T;
};

export type HtmlCacheEntryData =
  | EntryBereich
  | EntryInformation
  | EntryLage
  | EntryLeistung
  | EntryOrg
  | EntryOrgSuche
  | EntryStartseite
  | EntrySublage
  | EntrySuche;

type EntryBereich = {
  patch: HtmlPatchBereich;
  screenRequest: ScreenRequestBereich;
};
type EntryInformation = {
  patch: HtmlPatchInformation;
  screenRequest: ScreenRequestInformation;
};
type EntryLage = { patch: HtmlPatchLage; screenRequest: ScreenRequestLage };
type EntryLeistung = {
  patch: HtmlPatchLeistung;
  screenRequest: ScreenRequestLeistung;
};
type EntryOrg = { patch: HtmlPatchOrg; screenRequest: ScreenRequestOrg };
type EntryOrgSuche = {
  patch: HtmlPatchOrgSuche;
  screenRequest: ScreenRequestOrgSuche;
};
type EntryStartseite = {
  patch: HtmlPatchStartseite;
  screenRequest: ScreenRequestStartseite;
};
type EntrySublage = {
  patch: HtmlPatchSublage;
  screenRequest: ScreenRequestSublage;
};
type EntrySuche = { patch: HtmlPatchSuche; screenRequest: ScreenRequestSuche };

export type HtmlCacheEntry = {
  lastUsed: number;
  notFound: boolean;
  data: HtmlCacheEntryData;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function initialCitySearch() {
  return {
    // Daten vom Server
    suggestions: {
      data: [] as Gebiet[],
      forRequest: undefined as UndefOr<CitySearchRequest>,
      // true, wenn keine Daten für die angegebenen Parameter gefunden wurden
      notFound: false,
    },
    // Eingabedaten
    input: {
      searchTerm: '',
    },
    selectedOrt: SELECTED_ORT_HESSEN as SelectedOrt,
    // sollen Validierungsfehler aktuell angezeigt werden?
    showValidationErrors: false as boolean,
    // Feedback bei der Ortssuche anzeigen
    feedbackHint: false as boolean,
  };
}

export type MapOnlineService = {
  detailsShown: boolean;
  dropdown: Dropdown;
  // Eingabedaten
  input: {
    // undefined bedeutet, dass der Name des gewählten Regschl angezeigt wird
    searchTerm: string | undefined;
  };
  selectedRegschl: string;
};
export const INITIAL_MAP_ONLINE_SERVICE: MapOnlineService = {
  detailsShown: false,
  dropdown: { visible: false, selection: undefined },
  // Eingabedaten
  input: {
    // undefined bedeutet, dass der Name des gewählten Regschl angezeigt wird
    searchTerm: undefined,
  },
  selectedRegschl: '060000000000',
};

export type ChatbotState = {
  loaded: boolean;
  loading: boolean;
  privacyAccepted: boolean;
};
function chatbotState(): ChatbotState {
  return {
    loaded: false,
    loading: false,
    privacyAccepted: getChatbotPrivacyAccepted(),
  };
}

export type NfkState = {
  loading: boolean;
  privacyAccepted: boolean;
};
function nfkState(): NfkState {
  return {
    loading: false,
    privacyAccepted: getNfkPrivacyAccepted(),
  };
}

export type PvLagenByCode = Partial<
  Record<string, { name: string; iconSvgSafeHtml: string | undefined }>
>;
export type PvLagenCounts = Partial<Record<string, number>>;

export type StateMapData = {
  kommunen: MapData;
  landkreise: MapData;
};

type AbortRequestsConcrete = {
  bereichsseite: AbortRequestFor<BereichRequest>;
  citySearch: AbortRequestFor<CitySearchRequest>;
  information: AbortRequestFor<InformationRequest>;
  lagenseite: AbortRequestFor<LagenRequest>;
  leistung: AbortRequestFor<LeistungRequestWithRegschl>;
  mapOnlineServicesCounts: AbortRequestFor<undefined>;
  mapOnlineServicesData: AbortRequestFor<undefined>;
  organisationseinheit: AbortRequestFor<OrgRequest>;
  regschlSearch: AbortRequestFor<RegschlSearchRequest>;
  search: AbortRequestFor<SearchRequest>;
  searchMore: AbortRequestFor<SearchMoreRequest>;
  searchSuggestion: AbortRequestFor<SearchSuggestionRequest>;
  startseite: AbortRequestFor<undefined>;
  sublagenseite: AbortRequestFor<SublagenRequest>;
};
type AbortRequests = {
  [key in keyof AbortRequestsConcrete]: AbortRequestsConcrete[key] | undefined;
};
export const abortRequests: Map<number, () => void> = new Map<
  number,
  () => void
>();

let nextAbortRequestNumber = 0;
export function storeAbortRequest<const K extends keyof AbortRequests>(
  state: Draft<State>,
  which: K,
  abortRequest: Omit<AbortRequestsConcrete[K], 'abortRequest'> & {
    abortRequest: () => void;
  },
) {
  removeAbortRequest(state, which);
  const reqNumber = nextAbortRequestNumber++;
  abortRequests.set(reqNumber, abortRequest.abortRequest);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line no-param-reassign
  state.abortRequest[which] = {
    abortRequest: reqNumber,
    forRequest: abortRequest.forRequest,
  };
}

export function removeAbortRequest<const K extends keyof AbortRequests>(
  state: Draft<State>,
  which: K,
) {
  const reqNumber = state.abortRequest[which]?.abortRequest;
  if (reqNumber !== undefined) {
    abortRequests.delete(reqNumber);
  }
  // eslint-disable-next-line no-param-reassign
  state.abortRequest[which] = undefined;
}

/** Initialer Zustand der Anwendung */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createInitialState(initialScreen: Screen) {
  return {
    // enthält Abbruchfunktionen für laufende Backend-Requests
    abortRequest: {} as AbortRequests,
    appWasUpdated: false,
    // Alle Screens: Ortssuche
    citySearch: initialCitySearch(),
    // true, wenn direkt auf die unkompilierten CSS-Quellen verwiesen werden soll
    cssSourceLink: false,
    dropdowns: {
      [Dropdowns.CITY]: {
        visible: false,
        selection: undefined,
      } as Dropdown,
      [Dropdowns.SEARCH]: {
        visible: false,
        selection: undefined,
      } as Dropdown,
    },
    // enthält Exception, wenn ein Fehler aufgetreten ist
    error: {
      bereichsseite: undefined as UndefOrError,
      citySearch: undefined as UndefOrError,
      information: undefined as UndefOrError,
      lagenseite: undefined as UndefOrError,
      leistung: undefined as UndefOrError,
      organisationseinheit: undefined as UndefOrError,
      regschlSearch: undefined as UndefOrError,
      search: undefined as UndefOrError,
      searchSuggestion: undefined as UndefOrError,
      startseite: undefined as UndefOrError,
      sublagenseite: undefined as UndefOrError,
    },
    // flags aus der URL zum Testen von Features
    flags: getDefaultFlags(),
    // Cache für die einzelnen Seitendaten
    html: [] as HtmlCacheEntry[],
    htmlFrame: {
      footer: { safeHtml: '' },
    } as PageFrame,
    // screen "Information"
    information: {
      path:
        initialScreen === Screen.Information ? window.location.pathname : '',
      search:
        initialScreen === Screen.Information ? window.location.search : '',
    },
    // screen "Leistungsdetails"
    leistung: {
      // Anfragedaten
      request: undefined as UndefOr<LeistungRequest>,
    },
    // Onlinedienstekarte
    mapOnlineServices: {
      // true - Fehler beim Laden der Daten
      dataError: false,
      // Map: Karten-ID → interner Zustand der Karte
      mapStates: {} as Partial<Record<string, MapOnlineService>>,
      // Basisdaten (Landkreise/Kommunen), werden bei der Anzeige der Karte nachgeladen
      mapData: undefined as undefined | StateMapData,
      // Statistik-Zahlen: Onlinedienste pro Regionalschlüssel
      onlineServicesByRegschl: undefined as undefined | MapOnlineCountsResult,
    },
    // Status der Chatbot-Komponente
    chatbot: chatbotState(),
    // Status der Feedback-Komponente
    nfk: nfkState(),
    // screen "Orgdetails"
    org: {
      // Anfragedaten
      request: undefined as UndefOr<OrgRequest>,
    },
    // PV-Lagen
    pvLagen: {
      pvLagenByCode: undefined as PvLagenByCode | undefined,
      countsByRegschlAndCode: {} as Partial<Record<string, PvLagenCounts>>,
    },
    // aktuell angezeigter screen
    screen: initialScreen,
    // nötig für einmalige Aktionen beim ersten Laden oder nachdem ein URL-Anchor gesetzt wurde (Scrollen zum Anker
    // nachdem die Daten geladen wurden)
    // wird nach dem Laden auf false gesetzt
    scrollToAnchorNeeded: true,
    // screen "Suche"
    search: {
      // Themen einklappen in der Mobil-Ansicht
      collapseThemen: true,
      // Suchdaten
      request: {
        // Filter ("Navigation", Drop-Downs und Switches)
        filter: {
          pvLage: undefined,
          type: [],
        },
        regschlFilterTypes: undefined,
        regschlWahl: undefined,
        searchTerm: '',
      } as StateSearchRequestType,
      // Daten vom Server
      results: {
        data: undefined as UndefOr<SearchResult>,
        forRequest: undefined as UndefOr<SearchRequest>,
        // true, wenn die letzte Suche erfolglos war
        notFound: false,
        previewOnly: {
          editorialPages: true,
          leistungen: true,
          orgeinheiten: true,
        },
      },
      // sollen Validierungsfehler aktuell angezeigt werden?
      showValidationErrors: false,
      // Daten für die Vorschlagsliste
      suggestionData: [] as SearchSuggestion[],
    },
  };
}

function getDefaultFlags(): Flags {
  return window.hzd_og_flags
    ? flagsToObject(window.hzd_og_flags)
    : ({} as Flags);
}

export type State = ReturnType<typeof createInitialState>;
