import type { Action, Reducer } from '@reduxjs/toolkit';
import { createEvent, TriggeredBy } from '../reducer/createEvent';
import type { State } from '../state/createInitialState';
import { Screen } from '../view';
import type { SimpleStore } from '../state/SimpleStore';
import type { HtmlPatch } from '../action/htmlTypes';
import type { ServerData } from '../render/cached-incremental-dom';

export type URLPathSearchParams = {
  path: string;
  params: URLSearchParams;
};

export type MyHistoryImplType = {
  handleUrlChanged?: (state: State) => void;
  loadData: (simpleStore: SimpleStore) => void;
  stateToUrl: (state: State) => URLPathSearchParams;
  urlToState: (
    state1: State,
    urlPathSearchParams: URLPathSearchParams,
    serverData?: {
      patch: HtmlPatch;
      data?: ServerData;
    },
  ) => State;
};

export const EVENT_URL_CHANGED = createEvent<{
  location: string;
}>('EVENT_URL_CHANGED', TriggeredBy.User);
let previousLocation = stripHashFromLocation(window.location);

function stripHashFromLocation(location: Location): string {
  const locWithHash = String(location);
  const startOfHash = locWithHash.indexOf('#');
  if (startOfHash > -1) {
    return locWithHash.substring(0, startOfHash);
  } else {
    return locWithHash;
  }
}

export default class MyHistory {
  private readonly handleUrlChanged?: (state: State) => void;

  private readonly loadData: (simpleStore: SimpleStore) => void;

  private readonly stateToUrl: (state: State) => URLPathSearchParams;

  private readonly urlToState: MyHistoryImplType['urlToState'];

  private currentPathSearchParams: URLPathSearchParams;

  private simpleStore: undefined | SimpleStore;

  private currentUrlIsVolatile = false;

  /**
   * Erzeugt ein neues MyHistory-Objekt.
   * Wenn das Objekt nicht mehr gebraucht wird, sollte die Methode "disconnect()" aufgerufen werden
   * @param handleUrlChanged Optionales callback, wird aufgerufen, wenn die URL durch eine Änderung des States oder durch den Anwender geändert wurde
   * @param loadData Callback, wird aufgerufen, wenn initial oder nach einer (durch den Anwender getriggerten) Browser-Navigation Daten geladen werden sollten
   * @param stateToUrl function (state) => URLSearchParams
   * @param urlToState function (state, URLSearchParams) => state
   */
  constructor({
    handleUrlChanged,
    loadData,
    stateToUrl,
    urlToState,
  }: MyHistoryImplType) {
    this.handleUrlChanged = handleUrlChanged;
    this.loadData = loadData;
    this.navigate = this.navigate.bind(this);
    this.stateToUrl = stateToUrl;
    this.urlToState = urlToState;
    this.currentPathSearchParams = getPathSearchParams();
    window.addEventListener('popstate', this.handlePopState);
  }

  /**
   * Verbindet sich mit dem angegebenen Store.
   */
  setStore(simpleStore: SimpleStore): void {
    this.simpleStore = simpleStore;
  }

  /**
   * Löst die Verbindung mit den Browser-Events. Sollte aufgerufen werden, wenn das Objekt nicht mehr benötigt wird, da
   * ansonsten ein Memory-Leak auftreten kann.
   */
  disconnect(): void {
    window.removeEventListener('popstate', this.handlePopState);
  }

  /**
   * Intern; wird aufgerufen, wenn ein popstate-Event auftritt.
   */
  private handlePopState: () => void = () => {
    this.currentPathSearchParams = getPathSearchParams();

    const currentLocationWithoutHash = stripHashFromLocation(window.location);
    if (previousLocation === currentLocationWithoutHash) {
      return;
    }
    previousLocation = currentLocationWithoutHash;
    if (this.simpleStore) {
      this.simpleStore.dispatch(
        EVENT_URL_CHANGED({
          location: window.location.toString(),
        }),
      );
      this.loadData(this.simpleStore);
      if (this.handleUrlChanged) {
        this.handleUrlChanged(this.simpleStore.getState());
      }
    }
  };

  navigate: (url: string | URL) => boolean = (url) => {
    const { simpleStore } = this;
    if (!simpleStore) {
      window.location.assign(url);
    } else {
      const urlPathSearchParams = getPathSearchParams(url);
      const newState = this.urlToState(
        simpleStore.getState(),
        urlPathSearchParams,
      );
      if (newState.screen === Screen.Information) {
        return false;
      }
      const urlViaState = urlPathSearchParamsToString(
        this.stateToUrl(newState),
      );

      // if and only if the URL is a proper URL, use internal navigation
      if (urlViaState !== urlPathSearchParamsToString(urlPathSearchParams)) {
        // give the server a chance to probably do a redirect
        return false;
      } else {
        window.history.pushState(undefined, '', url);
        this.handlePopState();
      }
    }
    return true;
  };

  updateUrlFromState(volatile: boolean, forceReplace: boolean): void {
    // überschrieben wird nur, wenn das vorherige und aktuelle, durch den User ausgelöste Event volatil ist,
    // da das letzte volatile Event vor einer nicht-volatilen Änderung ebenfalls in der Historie erhalten bleiben soll
    // Bsp: bei Eingabe in das Suchfeld und anschließender Suche soll die Url nach dem letzten Tippen gespeichert werden,
    // obwohl das Event selbst volatil ist
    const replace = (volatile && this.currentUrlIsVolatile) || forceReplace;
    this.handleStateChanged(replace);
    this.currentUrlIsVolatile = volatile;
  }

  /**
   * Intern; wird aufgerufen, wenn sich der State ändert.
   */
  private handleStateChanged(replaceState: boolean): void {
    if (!this.simpleStore) {
      throw new Error('No store connected');
    }
    const state = this.simpleStore.getState();
    const newPathSearchParams = this.stateToUrl(state);
    const newPathSearchParamsString =
      urlPathSearchParamsToString(newPathSearchParams);
    const currentPathSearchParamsString = urlPathSearchParamsToString(
      this.currentPathSearchParams,
    );
    if (currentPathSearchParamsString !== newPathSearchParamsString) {
      this.currentPathSearchParams = newPathSearchParams;
      const historyState = undefined;
      if (replaceState) {
        window.history.replaceState(
          historyState,
          '',
          newPathSearchParamsString,
        );
      } else {
        window.history.pushState(historyState, '', newPathSearchParamsString);
      }
      previousLocation = stripHashFromLocation(window.location);
      if (this.handleUrlChanged) {
        this.handleUrlChanged(state);
      }
    }
  }

  /**
   * Erweitert den angegebenen initialen Zustand um Werte aus der aktuellen URL und HTML
   * @param state Initialer Zustand
   * @param serverData Initiale Serverdaten
   * @returns {*} Angepasster initialer Zustand
   */
  initialState(
    state: State,
    serverData: {
      patch: HtmlPatch;
      data?: ServerData;
    },
  ): State {
    return this.urlToState(state, getPathSearchParams(), serverData);
  }

  /**
   * Triggert das initiale Laden der Daten und aktualisiert die Url
   */
  loadInitialDataAndSetUrl(): void {
    if (!this.simpleStore) {
      throw new Error('No store connected');
    }
    const url = urlPathSearchParamsToString(
      this.stateToUrl(this.simpleStore.getState()),
    );
    const locationHash = window.location.hash;
    window.history.replaceState(undefined, '', url + locationHash);
    this.loadData(this.simpleStore);
  }

  /**
   * Erweitert den angegebenen Root-Reducer um die Behandlung der eigenen URL-Events.
   * @param next Root-Reducer
   * @returns {function(...[*]=)} Erweiterter Root-Reducer
   */
  reducer<S extends State, A extends Action>(next: Reducer<S, A>) {
    return (state: S, event: A): State | S => {
      if (EVENT_URL_CHANGED.match(event)) {
        const { location } = event.payload;
        return this.urlToState(state, getPathSearchParams(location));
      } else {
        return next(state, event);
      }
    };
  }
}

export function modifyInitialURL(): void {
  if (detectLatin()) {
    removeLatinEncoding();
  }
  /*
  // vermutlich obsolet, da wir die die URL der Seite,
  // auf der wir das JS starten, selber serverseitig steuern
  const path = APP_INITIAL_PATH();
  const { search, hash } = document.location;
  window.history.replaceState(undefined, '', path + search + hash);
  */
}

function detectLatin(): boolean {
  try {
    decodeURI(document.location.search);
    return false;
  } catch (e) {
    return true;
  }
}

function removeLatinEncoding() {
  const newUrl = `${document.location.pathname}${unescape(
    document.location.search + document.location.hash,
  )}`;
  window.history.replaceState(undefined, '', newUrl);
}

function getPathSearchParams(
  location: string | URL = document.location.toString(),
) {
  const url = new URL(location, window.location.href);
  const params = new URLSearchParams(url.search);
  const path = url.pathname;
  return { params, path };
}

const URL_EMPTY_PARAM_REGEXP = /=(&|$)/g;

export function urlPathSearchParamsToString(
  pathParams: URLPathSearchParams,
): string {
  const { path, params } = pathParams;
  const paramsSorted = new URLSearchParams(params);
  paramsSorted.sort();
  const paramsString = paramsSorted
    .toString()
    .replaceAll(URL_EMPTY_PARAM_REGEXP, '$1');
  const separator = paramsString.length ? '?' : '';
  return `${path}${separator}${paramsString}`;
}
