import isEmpty from 'lodash/fp/isEmpty';
import type { Flags } from '../../history/flags';
import { flagsToString } from '../../history/flags';
import { mapValues } from '../../lib/lodash';
import { filterUndefinedOrEmptyAttributes } from '../../util/filterUndefinedOrEmptyAttributes';
import { mapBooleanAttributes } from '../../util/mapBooleanAttributes';

export type ErrorWithStatus = Error & { status: number };

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText) as ErrorWithStatus;
    error.status = response.status;
    throw error;
  }
}

type PostGetResult<T> = {
  abortRequest: () => void;
  result: Promise<T>;
};

/*
export function postNoRedirect<T>({
  url,
  data,
}: {
  url: string;
  data: Partial<Record<string, string>>;
}): PostGetResult<T> {
  const abortController = new AbortController();
  const { signal } = abortController;

  const hasData = data && !isEmpty(data);
  const method = 'POST';
  const headers = hasData ? [['Content-Type', 'application/json']] : [];
  const body = hasData ? JSON.stringify(data) : undefined;
  return {
    abortRequest: abortController.abort,
    result: fetch(url, {
      method,
      headers,
      body,
      signal,
    })
      .then(checkStatus)
      .then(parseJson),
  };
}
*/

export function prepareData(
  data:
    | undefined
    | Partial<Record<string, string | string[] | number | boolean | undefined>>,
): undefined | Partial<Record<string, string>> {
  if (data === undefined) {
    return undefined;
  } else {
    // alle Werte, die nicht string, undefined oder boolean sind nach String umwandeln
    const data1: Partial<Record<string, string | boolean | undefined>> =
      mapValues((value: string | string[] | number | boolean | undefined) =>
        // eslint-disable-next-line no-nested-ternary
        typeof value === 'undefined' ||
        typeof value === 'string' ||
        typeof value === 'boolean'
          ? value
          : String(value),
      )(data);
    // alle keys mit Wert undefined oder '' entfernen
    const data2 = filterUndefinedOrEmptyAttributes(data1);
    // alle keys mit Wert false entfernen und Wert true auf '' ändern
    return mapBooleanAttributes(data2);
  }
}

export function paramsToString(data: Partial<Record<string, string>>): string {
  const keysWithEmptyValues = Object.keys(data).filter(
    (key) => data[key] === '',
  );
  const dataWithoutEmpty = filterUndefinedOrEmptyAttributes(data);
  const dataWithoutEmptyAsUrlParams = new URLSearchParams(dataWithoutEmpty);
  dataWithoutEmptyAsUrlParams.sort();
  const queryWithoutEmpty = dataWithoutEmptyAsUrlParams.toString();
  const queryWithoutEmptyArr = queryWithoutEmpty ? [queryWithoutEmpty] : [];
  return [
    ...queryWithoutEmptyArr,
    ...keysWithEmptyValues.map((key) => encodeURIComponent(key)),
  ].join('&');
}

export function get<
  REQUEST extends Partial<
    Record<string, string | string[] | number | boolean | undefined>
  >,
  RESPONSE,
>({
  url,
  data,
  flags,
}: {
  url: string;
  data: REQUEST;
  flags: Flags;
}): PostGetResult<RESPONSE> {
  const abortController = new AbortController();
  const { signal } = abortController;

  if (flags.fail_request && url.includes(flags.fail_request)) {
    return {
      abortRequest: abortController.abort.bind(abortController),
      result: Promise.reject(Error('Failed request due to flag')),
    };
  }
  const preparedData = prepareData({ ...data, flags: flagsToString(flags) });
  const method = 'GET';
  const requestUrl =
    preparedData && !isEmpty(preparedData)
      ? `${url}?${paramsToString(preparedData)}`
      : url;
  return {
    abortRequest: abortController.abort.bind(abortController),
    result: fetch(requestUrl, {
      method,
      signal,
    })
      .then(checkStatus)
      .then((body) => body.json() as Promise<RESPONSE>),
  };
}

export function postNoRedirect<
  REQUEST extends Partial<
    Record<string, string | string[] | number | boolean | undefined>
  >,
  RESPONSE,
>({
  url,
  data,
  flags,
}: {
  url: string;
  data: REQUEST;
  flags: Flags;
}): PostGetResult<RESPONSE> {
  const abortController = new AbortController();
  const { signal } = abortController;

  if (flags.fail_request && url.includes(flags.fail_request)) {
    return {
      abortRequest: abortController.abort.bind(abortController),
      result: Promise.reject(Error('Failed request due to flag')),
    };
  }
  const preparedData = prepareData({ ...data, flags: flagsToString(flags) });
  const method = 'POST';
  const requestUrl =
    preparedData && !isEmpty(preparedData)
      ? url + (url.includes('?') ? '&' : '?') + paramsToString(preparedData)
      : url;
  return {
    abortRequest: abortController.abort.bind(abortController),
    result: fetch(requestUrl, {
      method,
      signal,
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'manual',
    })
      .then((resp) => {
        if (resp.type === 'opaqueredirect') {
          window.location.assign(requestUrl);
        }
        return resp;
      })
      .then(checkStatus)
      .then((resp) => resp.json() as Promise<RESPONSE>),
  };
}

export function isAbortError(e: Error): boolean {
  return (
    e.name === 'AbortError' ||
    (e instanceof DOMException && e.code === DOMException.ABORT_ERR)
  );
}
export function isErrorWithStatus(
  e: Error | ErrorWithStatus,
): e is ErrorWithStatus {
  return 'status' in e;
}
