import type {
  SearchRequest,
  SearchResult,
  SearchResultData,
  SearchType,
} from '../../action/search/searchTypes';
import {
  SearchResultType,
  SearchTypeEnum,
} from '../../action/search/searchTypes';
import { isErrorWithStatus } from '../../action/util/fetch';
import { TypeFilter } from '../../constants';
import type {
  RenderElement,
  RenderParam,
  RenderResultWithSsr,
} from '../../lib/renderTypes';
import { div, h1, p, span } from '../../render/html';
import {
  ERROR_SEARCH,
  NOT_FOUND_SEARCH,
  LAND_HESSEN,
  SEARCH_HAS_CONTACT,
  SEARCH_HAS_ONLINE_SERVICE,
  SEARCH_PLACE_REQUIRED,
  SEARCH_HAS_LEICHTE_SPRACHE,
} from '../../texts';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import { Screen } from '../../view';
import combinePlaceNames from '../util/combinePlaceNames';
import error from './error';
import { loadingIndicator } from './loading';
import './results.css';
import { askSearchWhere } from './results/askSearchWhere';
import { askDidYouMean } from './results/didYouMean';
import { resultListMulti } from './results/resultList';
import type { CountAndWord } from './results/resultTypes';
import { wordsSingleMultiMap } from './results/resultTypes';
import { sectionSeiteninhalt } from './sectionSeiteninhalt';
import type { SimpleStore } from '../../state/SimpleStore';
import { rawImageResizable } from '../elements/rawImage';
import buildingsIcon from '../share-icons/buildings.svg';
import displayIcon from '../share-icons/display.svg';
import mapIcon from '../share-icons/map.svg';
import leichteSpracheIcon from '../share-icons/leichte_sprache.svg';

export function results(
  simpleStore: SimpleStore,
  meta: RenderElement,
  content: RenderElement[],
  loading: RenderResultWithSsr[],
  cssClass = '',
): RenderParam {
  const state = simpleStore.getState();
  const { data } = state.search.results;
  const searchResultLoading = Boolean(state.abortRequest.search);

  if (state.error.search) {
    return renderResultsError(state.error.search);
  }

  if (!data && !searchResultLoading) {
    return undefined;
  }

  return sectionSeiteninhalt(
    {
      ariaLabel: 'Suchergebnisse',
      class: `a-position-relative a-background-color-white ${cssClass}`,
    },
    [
      h1({ class: 'h3' }, 'Suchergebnisse'),
      div(
        {
          class: searchResultLoading
            ? 'og-results-transparent'
            : 'og-results-intransparent',
        },
        [meta, ...content],
      ),
      ...loading,
    ],
  );
}

export function renderResultsContent(
  simpleStore: SimpleStore,
  shouldRenderAskList = true,
  orgAriaHint: string | undefined = undefined,
): (RenderResultWithSsr | undefined)[] {
  const state = simpleStore.getState();
  const { results: searchResults } = state.search;
  if (!searchResults.data || !searchResults.forRequest) {
    return [];
  } else {
    const { searchType } = searchResults.data;
    const { searchTerm } = searchResults.forRequest;
    const searchMoreResultLoading = Boolean(state.abortRequest.searchMore);
    const searchData =
      searchResults.data.type === SearchResultType.MESSAGE
        ? {
            type: searchResults.data.type,
            message: searchResults.data.message,
          }
        : {
            type: searchResults.data.type,
            result: searchResults.data.result,
            message: searchResults.data.message,
          };

    switch (searchType.type) {
      case SearchTypeEnum.HESSEN:
      case SearchTypeEnum.CITY: {
        const searchTermToShow =
          searchType.type === SearchTypeEnum.HESSEN
            ? searchTerm
            : searchType.internalSearchTerm;
        const result: (RenderResultWithSsr | undefined)[] = askDidYouMean({
          simpleStore,
          state,
        });
        return result
          .concat(
            askSearchWhere({
              simpleStore,
              state,
              shouldRenderAskList,
            }),
          )
          .concat(
            resultListMulti({
              simpleStore,
              loadingMore: searchMoreResultLoading,
              orgAriaHint,
              searchResults: searchData,
              showPreviewOnly: searchResults.previewOnly,
              searchTerm: searchTermToShow,
              searchType,
            }),
          );
      }
      default:
        return [error('Fehler beim Auswerten der Suchergebnisse')];
    }
  }
}

export function renderResultsError(searchError: Error): RenderResultWithSsr {
  if (isErrorWithStatus(searchError) && searchError.status === 404) {
    return error(NOT_FOUND_SEARCH);
  } else {
    return error(ERROR_SEARCH);
  }
}

export function renderResultsLoading(
  dataDisplayed: boolean,
): RenderResultWithSsr[] {
  return [
    div(
      {
        class: `og-search-results-loading ${
          dataDisplayed ? 'og-search-results-loading-overlay' : ''
        }`,
      },
      loadingIndicator(),
    ),
  ];
}

export function buildCountInfoText({
  forText,
  inText,
  entries,
}: {
  forText: string | undefined;
  inText: string | undefined;
  entries: CountAndWord[];
}): string {
  const parts = entries.map(
    (entry) =>
      `${String(entry.count)} ${
        entry.count === 1 ? entry.wordSingle : entry.wordMulti
      }`,
  );
  const forPart = forText ? `für "${forText}" ` : '';
  const inPart = inText ? `in ${inText} ` : '';
  if (parts.length === 0) {
    return `Es wurden ${forPart}${inPart}keine Ergebnisse gefunden.`;
  } else if (parts.length === 1) {
    if (entries[0].count === 1) {
      return `Es wurde ${forPart}${inPart}${parts[0]} gefunden.`;
    } else {
      return `Es wurden ${forPart}${inPart}${parts[0]} gefunden.`;
    }
  } else {
    const first = parts.slice(0, parts.length - 1);
    const last = parts[parts.length - 1];
    return `Es wurden ${forPart}${inPart}${first.join(
      ', ',
    )} und ${last} gefunden.`;
  }
}

export function foundText(
  simpleStore: SimpleStore,
  result: SearchResult,
  forRequest: SearchRequest,
) {
  if (result.type === SearchResultType.MESSAGE) {
    return undefined;
  }

  const { searchType } = result;
  const { searchTerm } = forRequest;
  switch (searchType.type) {
    case SearchTypeEnum.CITY:
      return foundTextResult(
        simpleStore,
        result.result,
        searchType,
        searchType.internalSearchTerm,
      );
    case SearchTypeEnum.HESSEN:
      return foundTextResult(
        simpleStore,
        result.result,
        searchType,
        searchTerm,
      );
    default:
      return undefined;
  }
}

function foundTextResult(
  simpleStore: SimpleStore,
  searchResultData: SearchResultData,
  searchType: SearchType,
  searchTerm: string,
) {
  const countEditorialPages = searchResultData.count.editorialPages || 0;
  const countLeistungen = searchResultData.count.leistungen || 0;
  const countOrgeinheiten = searchResultData.count.orgeinheiten || 0;

  let placeText: string | undefined;
  switch (searchType.type) {
    case SearchTypeEnum.HESSEN:
      placeText = LAND_HESSEN;
      break;
    case SearchTypeEnum.CITY:
      placeText = combinePlaceNames(searchType.city);
      break;
    default:
      placeText = undefined;
      break;
  }

  let types: TypeFilter[];
  const state = simpleStore.getState();
  switch (state.screen) {
    case Screen.Bereich:
    case Screen.Lage:
    case Screen.Sublage:
    case Screen.Suche:
      types = simpleStore.getState().search.request.filter.type;
      break;
    case Screen.OrgSuche:
      types = [TypeFilter.orgeinheit];
      break;
    case Screen.Leistung:
    case Screen.Org:
    case Screen.Startseite:
    case Screen.Information:
      // Komponente wird nicht verwendet
      types = [];
      break;
    default:
      throw new UnreachableCaseError(state.screen);
  }

  const countAndWords: CountAndWord[] = [];
  if (types.length === 0 || types.includes(TypeFilter.leistung)) {
    countAndWords.push({
      count: countLeistungen,
      ...wordsSingleMultiMap.Leistung,
    });
  }
  if (types.length === 0 || types.includes(TypeFilter.orgeinheit)) {
    countAndWords.push({
      count: countOrgeinheiten,
      ...wordsSingleMultiMap.Dienststelle,
    });
  }
  if (types.length === 0 || types.includes(TypeFilter.info)) {
    countAndWords.push({
      count: countEditorialPages,
      ...wordsSingleMultiMap.Information,
    });
  }

  return p(
    { class: 'paragraph bg-light p-3 font-font-roc-grotesk-w05-regular mb-4' },
    buildCountInfoText({
      forText: searchTerm,
      inText: placeText,
      entries: countAndWords,
    }),
  );
}

export function legend() {
  return div(
    {
      class:
        'bg-light d-flex flex-wrap column-gap-5 p-3 mb-4 font-font-roc-grotesk-w05-regular font-size-4',
      'aria-hidden': true,
    },
    [
      div({}, [
        div(
          { class: 'd-flex' },
          rawImageResizable({
            alt: SEARCH_HAS_ONLINE_SERVICE,
            class: 'og-type-icon',
            data: displayIcon,
          }),
          span({ class: '' }, SEARCH_HAS_ONLINE_SERVICE),
        ),
        div(
          { class: 'd-flex' },
          rawImageResizable({
            alt: SEARCH_HAS_CONTACT,
            class: 'og-type-icon',
            data: buildingsIcon,
          }),
          span(
            { class: 'font-font-roc-grotesk-w05-regular' },
            SEARCH_HAS_CONTACT,
          ),
        ),
      ]),
      div({}, [
        div(
          { class: 'd-flex' },
          rawImageResizable({
            alt: SEARCH_PLACE_REQUIRED,
            class: 'og-type-icon',
            data: mapIcon,
          }),
          span(
            { class: 'font-font-roc-grotesk-w05-regular' },
            SEARCH_PLACE_REQUIRED,
          ),
        ),
        div(
          { class: 'd-flex' },
          rawImageResizable({
            alt: SEARCH_HAS_LEICHTE_SPRACHE,
            class: 'og-type-icon',
            data: leichteSpracheIcon,
          }),
          span(
            { class: 'font-font-roc-grotesk-w05-regular' },
            SEARCH_HAS_LEICHTE_SPRACHE,
          ),
        ),
      ]),
    ],
  );
}
