import { applyProp, attributes, symbols } from 'incremental-dom';
import type { GenerateIdToken } from './common-render-types';

export function createGenerateIdToken(): GenerateIdToken {
  return {
    generatedId: undefined,
  };
}

export const KEEP_VALUE = 'hzd-incremental-dom-keep-value';

let nextId = 0;
function getNextId(): string {
  return (nextId++).toString(36);
}

function generateId(): string {
  if (__SERVER_SIDE_RENDERING__) {
    throw new Error('incremental-dom called inside Server-Side-Rendering');
  } else {
    return `c_${getNextId()}`;
  }
}

export function customizeIncrementalDom() {
  const incDomDefAttributes = attributes[symbols.default];
  // always apply parameter "value" as property (not attribute)
  attributes.value = function handleValueAttribute(
    element: Element,
    name: string,
    value: unknown,
  ) {
    // console.log(`name: ${name}; value: ${value}`);
    applyProp(element, name, value);
  };
  // always apply parameter "checked" as property (not attribute)
  attributes.checked = function handleValueAttribute(
    element: Element,
    name: string,
    value: unknown,
  ) {
    // console.log(`name: ${name}; value: ${value}`);
    applyProp(element, name, value);
  };
  // apply all parameters "prop-foo" as property "foo"
  // keep existing value with KEEP_VALUE
  attributes[symbols.default] = function incDomAttributes(
    element: Element,
    name: string,
    value: unknown,
  ) {
    // console.log('Incremental DOM attributes: ', args);
    try {
      let newValue = value;
      if (value === KEEP_VALUE) {
        newValue = element.getAttribute(name);
      } else if (
        typeof value === 'object' &&
        value != null &&
        'generatedId' in value
      ) {
        newValue = element.getAttribute(name);
        if (!newValue) {
          if (!value.generatedId) {
            // eslint-disable-next-line no-param-reassign
            value.generatedId = generateId();
          } else if (typeof value.generatedId !== 'string') {
            throw new Error(
              `Invalid type in generatedId attribute: ${typeof value.generatedId}`,
            );
          }
          newValue = value.generatedId;
        }
      }
      if (name.startsWith('prop-')) {
        // console.log('apply prop', element, name, value);
        applyProp(element, name.substring(5), newValue);
        return;
      }
      incDomDefAttributes(element, name, newValue);
    } catch (e) {
      console.error(
        'Error while setting attributes for node ',
        element,
        ', attribute/property ',
        name,
        ', value ',
        value,
      );
      return;
    }
  };

  /*
  // DEBUG incremental dom node creation/deletion
  notifications.nodesCreated = function onNodesCreated(...args) {
    console.log('Incremental DOM nodes created: ', args);
  };
  notifications.nodesDeleted = function onNodesDeleted(...args) {
    console.log('Incremental DOM nodes deleted: ', args);
  };
  */
}
