import { Dispatch } from 'redux';
import { patch } from 'incremental-dom';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import { button, span } from '../../../render/html';
import { mapBooleanAttributes } from '../../../util/mapBooleanAttributes';
import {
  BUTTON_CLOSE,
  FEEDBACK_BUTTON_LABEL,
  NFK_PRIVACY_HEADER,
  NFK_PRIVACY_SAFE_HTML,
  PRIVACY_ACCEPT,
  PRIVACY_REJECT,
  PRIVACY_REJECTED_HEADER,
  PRIVACY_REJECTED_SAFE_HTML,
} from '../../../texts';
import { NFK_PRIVACY_ACCEPTED } from '../../../reducer/nfkEvents';
import { setNfkPrivacyAccepted } from '../../../state/sessionStore';
import { ID_FEEDBACK_BUTTON } from './renderFeedbackButton';
import { ID_ASK_PRIVACY_MODAL, renderModal } from '../modal/renderModal';

const ID_ASK_PRIVACY_WRAPPER = 'hzd_vwp_nfk_ask_privacy_wrapper';

/** Feedback-Button vor Einwilligung */
export function renderFeedbackAskPrivacyButton(
  dispatch: Dispatch,
): RenderResultWithSsr[] {
  return [
    button(
      mapBooleanAttributes({
        class: 'btn btn-primary shadow',
        'data-hzd-action': 'feedback',
        'data-hzd-type': 'button',
        onclick: handleAskPrivacyButtonClicked(dispatch),
      }),
      span({ class: 'bi bi-send pe-2' }, ' '),
      FEEDBACK_BUTTON_LABEL,
    ),
  ];
}

/** Handler, wenn Einwilligung zugestimmt wurde */
function handleAskPrivacyAcceptedButtonClicked(dispatch: Dispatch) {
  return () => {
    dispatch(NFK_PRIVACY_ACCEPTED());
    setNfkPrivacyAccepted();
    // Zeit lassen zum Rendern des Buttons
    setImmediate(() => {
      const feedbackButton = document.getElementById(ID_FEEDBACK_BUTTON);
      if (feedbackButton) {
        feedbackButton.click();
      }
    });
  };
}

/** Handler, wenn Einwilligung verweigert wurde */
function handleAskPrivacyRejectedButtonClicked(outer: HTMLDivElement) {
  patch(
    outer,
    renderModal(PRIVACY_REJECTED_HEADER, PRIVACY_REJECTED_SAFE_HTML, [
      button(
        {
          type: 'button',
          class: 'btn btn-primary shadow',
          'data-bs-dismiss': 'modal',
        },
        [BUTTON_CLOSE],
      ),
    ]),
  );
  const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
  if (modalElement) {
    const modal = new window.bootstrap.Modal(modalElement);
    modalElement.addEventListener('hidden.bs.modal', () => {
      modal.dispose();
      document.body.removeChild(outer);
    });
    modal.show();
  }
}

/** Handler, wenn auf Feedback-Button (vor Einwilligung) geklickt wurde  */
function handleAskPrivacyButtonClicked(dispatch: Dispatch) {
  return () => {
    if (!document.getElementById(ID_ASK_PRIVACY_WRAPPER)) {
      const outer = document.createElement('div');
      outer.id = ID_ASK_PRIVACY_WRAPPER;
      document.body.appendChild(outer);
      let rejected = false;
      patch(
        outer,
        renderModal(NFK_PRIVACY_HEADER, NFK_PRIVACY_SAFE_HTML, [
          button(
            {
              type: 'button',
              class: 'btn btn-primary shadow',
              'data-bs-dismiss': 'modal',
              onclick: handleAskPrivacyAcceptedButtonClicked(dispatch),
            },
            [PRIVACY_ACCEPT],
          ),
          button(
            {
              type: 'button',
              class: 'btn btn-primary shadow',
              'data-bs-dismiss': 'modal',
              onclick: () => {
                rejected = true;
              },
            },
            [PRIVACY_REJECT],
          ),
        ]),
      );
      const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modalElement.addEventListener('hidden.bs.modal', () => {
          modal.dispose();
          if (rejected) {
            outer.innerHTML = '';
            handleAskPrivacyRejectedButtonClicked(outer);
          } else {
            document.body.removeChild(outer);
          }
        });
        modal.show();
      }
    }
  };
}
