import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, h1, p } from '../../render/html';
import orgSearch, { orgResults } from '../blocks/orgSearch';
import './orgSuche.css';
import type { SimpleStore } from '../../state/SimpleStore';
import { ORG_SEARCH_EXPLANATION } from '../../texts';
import h from '../../render/incremental-hyperscript';
import { share } from '../share';

export default function mainContent(
  simpleStore: SimpleStore,
): RenderResultWithSsr {
  const state = simpleStore.getState();
  return h.fragment([
    share(state.flags.share_generic === 'show', {
      title: document.title,
      url: String(document.location),
    }),
    div(
      { key: 'dynamic' },
      h1({ class: 'h3' }, 'Behördensuche'),
      p({ class: 'font-font-roc-grotesk-w05-regular' }, ORG_SEARCH_EXPLANATION),
      div({}, [
        orgSearch('og-org-search', simpleStore, 'Behörde\u00A0/ Stichwort'),
        orgResults(
          'og-org-results',
          simpleStore,
          'zum Nachfiltern oder Erweitern der Suchergebnisse können die Filter weiter oben verwendet werden',
        ),
      ]),
    ),
  ]);
}
