import { Dispatch } from 'redux';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import type { ChatbotScriptSrc } from '../../../constants';
import type {
  ChatbotState,
  HtmlCacheEntryData,
} from '../../../state/createInitialState';
import { Flags } from '../../../history/flags';
import { frameSelector } from '../../../render/cached-incremental-dom';
import { renderChatbotButtonUnloaded } from './renderChatbotButtonUnloaded';
import { renderChatbotButtonLoaded } from './renderChatbotButtonLoaded';
import { addChatbotHeaderIfMissing } from './addChatbotHeaderIfMissing';
import { renderChatbotNotAvailable } from './renderChatbotNotAvailable';
import { renderChatbotAskPrivacyButton } from './renderChatbotAskPrivacyButton';

function createHandleChatbotButtonClicked(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotOuterSelector: string | undefined,
  chatbotInnerSelector: string | undefined,
) {
  return async function handleChatbotButtonClicked(this: HTMLElement) {
    const loadSuccess = await addChatbotHeaderIfMissing(
      dispatch,
      flags,
      chatbotScriptSrc,
    );
    if (loadSuccess) {
      if (chatbotOuterSelector && chatbotInnerSelector) {
        const buttonElement = document
          .querySelector(frameSelector.chatbot)
          ?.querySelector(chatbotOuterSelector)
          ?.shadowRoot?.querySelector(chatbotInnerSelector);
        if (
          buttonElement &&
          'click' in buttonElement &&
          typeof buttonElement.click === 'function'
        ) {
          buttonElement.click();
        }
      }
    } else {
      renderChatbotNotAvailable();
    }
  };
}

function checkPrivacySettingsAndRenderChatbotButton(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string,
  chatbotOuterSelector: string | undefined,
  chatbotInnerSelector: string | undefined,
): RenderResultWithSsr | undefined {
  if (chatbotState.privacyAccepted) {
    if (chatbotState.loaded) {
      return renderChatbotButtonLoaded(chatbotSafeHtml);
    } else {
      return renderChatbotButtonUnloaded(
        chatbotState,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        createHandleChatbotButtonClicked(
          dispatch,
          flags,
          chatbotScriptSrc,
          chatbotOuterSelector,
          chatbotInnerSelector,
        ),
      );
    }
  } else {
    return renderChatbotAskPrivacyButton(dispatch);
  }
}

export function renderChatbot(
  dispatch: Dispatch,
  flags: Flags,
  cacheEntry: HtmlCacheEntryData,
  chatbotScriptSrc: ChatbotScriptSrc | undefined,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string | undefined,
  chatbotOuterSelector: string | undefined,
  chatbotInnerSelector: string | undefined,
): RenderResultWithSsr | undefined {
  if (
    chatbotScriptSrc &&
    chatbotSafeHtml &&
    cacheEntry.patch.pageElements.showChatbot
  ) {
    return checkPrivacySettingsAndRenderChatbotButton(
      dispatch,
      flags,
      chatbotScriptSrc,
      chatbotState,
      chatbotSafeHtml,
      chatbotOuterSelector,
      chatbotInnerSelector,
    );
  } else {
    return undefined;
  }
}
