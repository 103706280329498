import { fetchStatistics } from '../action/util/fetchStatistics';
import { SERVER_URL_ERROR_STATISTIC } from '../constants';
import { filterUndefinedOrEmptyAttributes } from './filterUndefinedOrEmptyAttributes';

export function logError(e: Error): void {
  if (typeof console === 'object' && typeof console.error === 'function') {
    console.error(e);
  }
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetchStatistics({
    url: SERVER_URL_ERROR_STATISTIC(),
    data: filterUndefinedOrEmptyAttributes({
      name: e.name,
      message: e.message,
      stack: e.stack,
    }),
  });
}
