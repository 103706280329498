import type { ReportHandler } from 'web-vitals';

export function reportWebVitals(onPerfEntry?: ReportHandler): void {
  if (onPerfEntry) {
    import('web-vitals')
      .then(({ getCLS, getFCP, getFID, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFCP(onPerfEntry);
        getFID(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch(() => {
        // ignore errors loading web-vitals
      });
  }
}
