/* eslint-disable no-param-reassign */
import { patch, patchOuter } from 'incremental-dom';
import type { RenderResultWithSsr } from '../lib/renderTypes';
import type { HtmlPatch, HtmlString, PageFrame } from '../action/htmlTypes';
import { HtmlHeadElement } from '../action/htmlTypes';
import { DOM_ID_SECTION_SHARE } from '../constants';
import type { PvLagenByCode, PvLagenCounts } from '../state/createInitialState';

export const frameSelector = {
  aside: '#aside',
  breadcrumbFooter: '.hzd-marker-breadcrumb-footer',
  breadcrumbHeader: '.hzd-marker-breadcrumb-header',
  chatbot: '#chatbot',
  content: '#main',
  feedback: '#feedbackcomponent',
  footer: '#footer',
  navigation: '#navigation',
  meta_description: 'html>head>meta[name="description"]',
  meta_keywords: 'html>head>meta[name="keywords"]',
  canonical_link: 'html>head>link[rel="canonical"]',
  geo_region: 'html>head>meta[name="geo.region"]',
  geo_placename: 'html>head>meta[name="geo.placename"]',
  geo_position: 'html>head>meta[name="geo.position"]',
  icbm: 'html>head>meta[name="ICBM"]',
  robots: 'html>head>meta[name="robots"]',
  share: `#${DOM_ID_SECTION_SHARE}`,
};

export const headerSelector = '#header';

function getAndCacheHtml(element?: Element): HtmlString {
  if (element) {
    const html = element.innerHTML;
    element.__cachedInnerHtml = html;
    return { safeHtml: html };
  } else {
    return { safeHtml: '' };
  }
}

function elementOrUndefined<T = Element>(selector: string) {
  const element = document.querySelector(selector);
  return (element ?? undefined) as T | undefined;
}

export type AdditionalData = {
  pvLagenByCode?: PvLagenByCode;
  pvLagenCounts?: PvLagenCounts;
};

export type ServerData = {
  params: {
    regschl: string;
  };
  data: AdditionalData;
};
export function cacheInitialHtml(): {
  frame: PageFrame;
  patch: HtmlPatch;
  serverData?: ServerData;
} {
  const htmlFooter = getAndCacheHtml(elementOrUndefined(frameSelector.footer));
  const pageFrame: PageFrame = {
    footer: htmlFooter,
  };

  const data = window.hzd_og_data;
  if (!data) {
    throw new Error('window.hzd_og_data is not defined');
  }

  return {
    frame: pageFrame,
    patch: {
      pageElements: {
        asideHtml: getAndCacheHtml(elementOrUndefined(frameSelector.aside)),
        breadcrumbHtml: getAndCacheHtml(
          // obwohl es mehrere Breadcrumbs gibt, nimm einfach die erste, da sie identisch sein sollen
          elementOrUndefined(frameSelector.breadcrumbHeader),
        ),
        contentHtml: getAndCacheHtml(elementOrUndefined(frameSelector.content)),
        htmlHead: {
          [HtmlHeadElement.TITLE]: document.title,
          [HtmlHeadElement.META_DESCRIPTION]:
            elementOrUndefined<HTMLMetaElement>(frameSelector.meta_description)
              ?.content,
          [HtmlHeadElement.META_KEYWORDS]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.meta_keywords,
          )?.content,
          [HtmlHeadElement.CANONICAL_LINK]:
            elementOrUndefined<HTMLLinkElement>(
              frameSelector.canonical_link,
            )?.getAttribute('href') ?? undefined,
          [HtmlHeadElement.GEO_PLACENAME]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.geo_placename,
          )?.content,
          [HtmlHeadElement.GEO_POSITION]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.geo_position,
          )?.content,
          [HtmlHeadElement.GEO_REGION]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.geo_region,
          )?.content,
          [HtmlHeadElement.ICBM]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.icbm,
          )?.content,
          [HtmlHeadElement.ROBOTS]: elementOrUndefined<HTMLMetaElement>(
            frameSelector.robots,
          )?.content,
        },
        navigationHtml: getAndCacheHtml(
          elementOrUndefined(frameSelector.navigation),
        ),
        showChatbot: data.showChatbot,
        odMapDomIds: data.odMapDomIds,
        drupalTitle: data.drupalTitle,
      },
      data: data.screenData,
    },
    serverData: data.serverData,
  };
}

export type HtmlOrRender = HtmlString | RenderResultWithSsr;

const loadingDivClass = 'hzd-page-loading-overlay';
const classInitialOff = 'hzd-initial-off';

export function patchInitialHtml(
  headMappings: Partial<Record<string, RenderResultWithSsr>>,
  frameMappings: Partial<Record<string, HtmlOrRender | undefined>>,
  innerMappings: Partial<Record<string, RenderResultWithSsr>>,
  loading: boolean,
) {
  Object.entries(frameMappings).forEach(([selector, htmlOrRenderValue]) => {
    const childElements = document.querySelectorAll(selector);
    Object.values(childElements).forEach((element) => {
      if (!htmlOrRenderValue) {
        if (element.__cachedInnerHtml !== '') {
          element.__cachedInnerHtml = '';
          element.innerHTML = '';
        }
      } else if ('safeHtml' in htmlOrRenderValue) {
        if (element.__cachedInnerHtml !== htmlOrRenderValue.safeHtml) {
          element.__cachedInnerHtml = htmlOrRenderValue.safeHtml;
          element.innerHTML = htmlOrRenderValue.safeHtml;
        }
      } else {
        delete element.__cachedInnerHtml;
        patch(element, htmlOrRenderValue);
      }
    });
  });
  // die inneren Patches müssen nach den äußeren Patches kommen, da ansonsten die äußeren die inneren überschreiben
  // könnten
  patchOuterAll(headMappings, innerMappings);

  const loadingDivs = document.getElementsByClassName(loadingDivClass);
  Array.from(loadingDivs).forEach((loadingDiv) => {
    const loadingIndicatorShown =
      !loadingDiv.classList.contains(classInitialOff);
    if (loading !== loadingIndicatorShown) {
      loadingDiv.classList.toggle(classInitialOff);
    }
  });
}

export function patchOuterAll(
  ...mappings: Partial<Record<string, RenderResultWithSsr>>[]
) {
  mappings.forEach((mapping) => {
    Object.entries(mapping).forEach(([selector, renderFn]) => {
      // um TypeScript zu beruhigen
      if (!renderFn) {
        return;
      }
      const childElements = document.querySelectorAll(selector);
      Object.values(childElements).forEach((element) =>
        patchOuter(element, renderFn),
      );
    });
  });
}
