import type { SimpleStore } from '../state/SimpleStore';
import { get } from '../action/util/fetch';
import {
  SERVER_URL_MAP_ONLINE_COUNTS,
  SERVER_URL_MAP_ONLINE_DATA,
} from '../constants';
import type { StateMapData } from '../state/createInitialState';
import { logError } from '../util/logError';
import {
  MAP_COUNTS_LOADING_ERROR,
  MAP_COUNTS_LOADING_FINISHED,
  MAP_COUNTS_LOADING_STARTED,
  MAP_DATA_LOADING_ERROR,
  MAP_DATA_LOADING_FINISHED,
  MAP_DATA_LOADING_STARTED,
} from './mapEvents';
import type { MapOnlineCountsResult } from './mapEvents';

export function loadMapDataIfMissing(simpleStore: SimpleStore) {
  const state = simpleStore.getState();
  if (
    !state.mapOnlineServices.onlineServicesByRegschl &&
    !state.abortRequest.mapOnlineServicesCounts
  ) {
    const { abortRequest, result } = get<
      Record<string, never>,
      MapOnlineCountsResult
    >({
      url: SERVER_URL_MAP_ONLINE_COUNTS(),
      data: {},
      flags: state.flags,
    });
    simpleStore.dispatch(MAP_COUNTS_LOADING_STARTED({ abortRequest }));
    result
      .then((data: MapOnlineCountsResult) => {
        simpleStore.dispatch(MAP_COUNTS_LOADING_FINISHED(data));
      })
      .catch((e: Error) => {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(MAP_COUNTS_LOADING_ERROR());
      });
  }
  if (
    !state.mapOnlineServices.mapData &&
    !state.abortRequest.mapOnlineServicesData
  ) {
    const { abortRequest, result } = get<Record<string, never>, StateMapData>({
      url: SERVER_URL_MAP_ONLINE_DATA(),
      data: {},
      flags: state.flags,
    });
    simpleStore.dispatch(MAP_DATA_LOADING_STARTED({ abortRequest }));
    result
      .then((data: StateMapData) => {
        simpleStore.dispatch(MAP_DATA_LOADING_FINISHED(data));
      })
      .catch((e: Error) => {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(MAP_DATA_LOADING_ERROR());
      });
  }
}
