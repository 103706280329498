import { Dispatch } from 'redux';
import { Flags } from '../../../history/flags';
import { ChatbotScriptSrc } from '../../../constants';
import {
  CHATBOT_LOADING_FAILED,
  CHATBOT_LOADING_FINISHED,
  CHATBOT_LOADING_STARTED,
} from '../../../reducer/chatbotEvents';

const ID_CHATBOT_SCRIPT = 'hzd_vwp_chatbot_script';
export async function addChatbotHeaderIfMissing(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (!document.getElementById(ID_CHATBOT_SCRIPT)) {
      dispatch(CHATBOT_LOADING_STARTED());
      let scriptSrc = chatbotScriptSrc.src;
      if (
        flags.fail_request &&
        chatbotScriptSrc.src.includes(flags.fail_request)
      ) {
        scriptSrc = 'https://url.invalid/invalid.js';
      }
      const script = document.createElement('script');
      script.setAttribute('id', ID_CHATBOT_SCRIPT);
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', scriptSrc);
      script.setAttribute('defer', 'defer');
      script.onload = () => {
        dispatch(CHATBOT_LOADING_FINISHED());
        resolve(true);
      };
      script.onerror = (event) => {
        console.error('Chatbot script could not be loaded', event);
        document.head.removeChild(script);
        dispatch(CHATBOT_LOADING_FAILED());
        resolve(false);
      };
      document.head.appendChild(script);
    } else {
      resolve(true);
    }
  });
}
