import { TypeFilter } from '../constants';
import { createEvent, TriggeredBy } from './createEvent';
import type {
  HtmlPatchBereich,
  HtmlPatchLage,
  HtmlPatchSublage,
} from '../action/htmlTypes';
import { RegschlFilterType, RegschlLevel } from '../action/search/searchTypes';
import type {
  ScreenRequestBereich,
  ScreenRequestLage,
  ScreenRequestSublage,
} from '../action/common/commonRequestTypes';
import type {
  BereichRequest,
  LagenRequest,
  SublagenRequest,
} from '../action/filter/PvLagenRequest';

/** Die gewählte Pv-Lage wurde geändert */
export const FILTER_PVLAGE_CHANGED = createEvent<string>(
  'FILTER_PVLAGE_CHANGED',
  TriggeredBy.User,
);
/** Filter für zuständige Gebiete wurde geändert */
export const FILTER_REGSCHL_FILTER_CHANGED = createEvent<{
  regschlFilterTypes: (RegschlLevel | RegschlFilterType)[] | undefined;
}>('FILTER_REGSCHL_FILTER_CHANGED', TriggeredBy.User);
/** Filter für Suchergebnis-Typ wurde geändert */
export const FILTER_TYPE_CHANGED = createEvent<TypeFilter[]>(
  'FILTER_TYPE_CHANGED',
  TriggeredBy.User,
);

/** Beim Laden der Bereichsseite ist ein Fehler aufgetreten */
export const LOAD_BEREICHSSEITE_ERROR = createEvent<Error>(
  'LOAD_BEREICHSSEITE_ERROR',
  TriggeredBy.Other,
);
/** Bereichsseite wurden erfolgreich geladen */
export const LOAD_BEREICHSSEITE_FINISHED = createEvent<{
  patch: HtmlPatchBereich;
  screenRequest: ScreenRequestBereich;
}>('LOAD_BEREICHSSEITE_FINISHED', TriggeredBy.Other);
/** Das Laden der Bereichsseite wurde gestartet */
export const LOAD_BEREICHSSEITE_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: BereichRequest;
}>('LOAD_BEREICHSSEITE_STARTED', TriggeredBy.Other);

/** Beim Laden der Lagenseite ist ein Fehler aufgetreten */
export const LOAD_LAGENSEITE_ERROR = createEvent<Error>(
  'LOAD_LAGENSEITE_ERROR',
  TriggeredBy.Other,
);
/** Lagenseite wurde erfolgreich geladen */
export const LOAD_LAGENSEITE_FINISHED = createEvent<{
  patch: HtmlPatchLage;
  screenRequest: ScreenRequestLage;
}>('LOAD_LAGENSEITE_FINISHED', TriggeredBy.Other);
/** Das Laden der Lagenseite wurde gestartet */
export const LOAD_LAGENSEITE_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: LagenRequest;
}>('LOAD_LAGENSEITE_STARTED', TriggeredBy.Other);

/** Beim Laden der Sublagenseite ist ein Fehler aufgetreten */
export const LOAD_SUBLAGENSEITE_ERROR = createEvent<Error>(
  'LOAD_SUBLAGENSEITE_ERROR',
  TriggeredBy.Other,
);
/** Sublagenseite wurde erfolgreich geladen */
export const LOAD_SUBLAGENSEITE_FINISHED = createEvent<{
  patch: HtmlPatchSublage;
  screenRequest: ScreenRequestSublage;
}>('LOAD_SUBLAGENSEITE_FINISHED', TriggeredBy.Other);
/** Das Laden der Sublagenseite wurde gestartet */
export const LOAD_SUBLAGENSEITE_STARTED = createEvent<{
  abortRequest: () => void;
  forRequest: SublagenRequest;
}>('LOAD_SUBLAGENSEITE_STARTED', TriggeredBy.Other);
