import type { Dispatch } from 'redux';
import {
  REGSCHL_HESSEN,
  SERVER_URL_SUCHE_ORT,
  SERVER_URL_SUCHE_REGSCHL,
} from '../../constants';
import {
  CITY_SEARCH_ABORTED,
  CITY_SEARCH_ERROR,
  CITY_SEARCH_FINISHED,
  CITY_SEARCH_NOT_FOUND,
  CITY_SEARCH_RESET,
  CITY_SEARCH_STARTED,
  REGSCHL_SEARCH_ERROR,
  REGSCHL_SEARCH_FINISHED,
  REGSCHL_SEARCH_STARTED,
} from './searchEvents';
import type { State } from '../../state/createInitialState';
import { logError } from '../../util/logError';
import { get, isAbortError, isErrorWithStatus } from '../util/fetch';
import { deepEquals } from '../../util/deepEquals';
import type {
  CitySearchRequest,
  Gebiet,
  GebietResult,
  RegschlSearchRequest,
} from './searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';

export function abortCitySearch(state: State, dispatch: Dispatch): void {
  if (state.abortRequest.citySearch) {
    abortRequestFor(state.abortRequest.citySearch);
    dispatch(CITY_SEARCH_ABORTED());
  }
}

type CitySearchResult = { orte: Gebiet[] };

export function searchCity(
  simpleStore: SimpleStore,
  request: { ort: string; onlyHessen: boolean },
): void {
  const state = simpleStore.getState();
  const { ort, onlyHessen } = request;
  const searchTermPrepared = ort.split('(')[0].trim();

  const serverRequest = {
    ort: searchTermPrepared,
    only_hessen: onlyHessen,
  };
  if (
    !deepEquals(serverRequest, state.citySearch.suggestions.forRequest) &&
    !deepEquals(serverRequest, state.abortRequest.citySearch?.forRequest)
  ) {
    const { abortRequest, result } = get<CitySearchRequest, CitySearchResult>({
      url: SERVER_URL_SUCHE_ORT(),
      data: serverRequest,
      flags: state.flags,
    });
    abortCitySearch(state, simpleStore.dispatch);
    simpleStore.dispatch(
      CITY_SEARCH_STARTED({ abortRequest, forRequest: serverRequest }),
    );
    result
      .then((data: CitySearchResult) => {
        const latestRequest =
          simpleStore.getState().abortRequest.citySearch?.forRequest;
        if (deepEquals(latestRequest, serverRequest)) {
          simpleStore.dispatch(
            CITY_SEARCH_FINISHED({
              data: data.orte,
              forRequest: serverRequest,
            }),
          );
        }
      })
      .catch((e: Error) => {
        const latestRequest =
          simpleStore.getState().abortRequest.citySearch?.forRequest;
        if (deepEquals(latestRequest, serverRequest)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(CITY_SEARCH_NOT_FOUND(serverRequest));
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(CITY_SEARCH_ERROR(e));
          }
        }
      });
  }
}

export function loadCityFromOrtId(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  const { selectedOrt } = state.citySearch;
  const regschl = selectedOrt.id;
  if (regschl && regschl !== REGSCHL_HESSEN) {
    if (selectedOrt.loadNeeded) {
      const request = {
        regschl,
      };
      const currentLoadingForRequest =
        state.abortRequest.regschlSearch?.forRequest;
      if (!deepEquals(request, currentLoadingForRequest)) {
        const { abortRequest, result } = get<
          RegschlSearchRequest,
          GebietResult
        >({
          url: SERVER_URL_SUCHE_REGSCHL(),
          data: request,
          flags: state.flags,
        });
        abortRequestFor(state.abortRequest.regschlSearch);
        simpleStore.dispatch(
          REGSCHL_SEARCH_STARTED({ forRequest: request, abortRequest }),
        );
        result
          .then((data: GebietResult) => {
            const latestRequest =
              simpleStore.getState().abortRequest.regschlSearch?.forRequest;
            if (deepEquals(latestRequest, request)) {
              simpleStore.dispatch(
                REGSCHL_SEARCH_FINISHED({
                  data,
                  forRequest: request,
                }),
              );
            }
          })
          .catch((e: Error) => {
            const latestRequest =
              simpleStore.getState().abortRequest.regschlSearch?.forRequest;
            if (deepEquals(latestRequest, request)) {
              // request war nicht erfolgreich
              if (isAbortError(e)) {
                // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
              } else if (isErrorWithStatus(e) && e.status === 404) {
                // keine Daten gefunden
                simpleStore.dispatch(
                  REGSCHL_SEARCH_FINISHED({
                    data: {
                      id: regschl,
                      isFindable: false,
                      land: `Code für den Ort ist unbekannt (${regschl}). Bitte Ort / Postleitzahl eingeben.`,
                      ort: undefined,
                      bezirk: undefined,
                      kreis: undefined,
                      verband: undefined,
                    },
                    forRequest: request,
                  }),
                );
              } else {
                // sonstiger Fehler
                logError(e);
                simpleStore.dispatch(REGSCHL_SEARCH_ERROR(e));
              }
            }
          });
      }
    }
  } else if (state.citySearch.input.searchTerm) {
    simpleStore.dispatch(CITY_SEARCH_RESET());
  }
}
