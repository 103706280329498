export async function activatePolyFills(): Promise<void> {
  await detectUnicodeRegExp();
  await detectFetch();
}

async function detectFetch() {
  if (typeof window.fetch !== 'function') {
    await import('./importFetchPolyfill');
  }
}

let resolveRewritePromise: (arg: typeof import('regexpu-core') | false) => void;

let rewritePattern:
  | typeof import('regexpu-core')
  | false
  | Promise<typeof import('regexpu-core') | false> = new Promise((resolve) => {
  resolveRewritePromise = resolve;
});

async function detectUnicodeRegExp() {
  // erkennen ob Unicode-RegExps unterstützt werden
  let supportUnicodeRegEx = false;
  try {
    // eslint-disable-next-line no-new,prefer-regex-literals
    new RegExp('', 'u');
    supportUnicodeRegEx = true;
  } catch (e) {
    // do nothing
  }
  if (supportUnicodeRegEx) {
    rewritePattern = false;
  } else {
    rewritePattern = (await import('regexpu-core')).default;
  }
  resolveRewritePromise(rewritePattern);
}

export function polyfillRegExp(regExp: string, flags: string): RegExp {
  if (typeof rewritePattern === 'object') {
    throw new Error(
      'polyfillRegExp must not be used in static initializers -> use asyncPolyfillRegExp instead',
    );
  }
  if (typeof rewritePattern === 'function') {
    return new RegExp(
      rewritePattern(regExp, flags, {
        unicodePropertyEscape: true,
      }),
      flags.replace('u', ''),
    );
  } else {
    return new RegExp(regExp, flags);
  }
}

export async function asyncPolyfillRegExp(
  regExp: string,
  flags: string,
): Promise<RegExp> {
  let rewrite;
  if (typeof rewritePattern === 'object') {
    rewrite = await rewritePattern;
  } else {
    rewrite = rewritePattern;
  }
  if (rewrite) {
    return new RegExp(
      rewrite(regExp, flags, {
        unicodePropertyEscape: true,
      }),
      flags.replace('u', ''),
    );
  } else {
    return new RegExp(regExp, flags);
  }
}
