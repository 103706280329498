import type { Draft } from 'immer';
import { produce } from 'immer';
import type { OrgRequest } from '../action/detail/detailTypes';
import { loadDataIfNecessary } from '../action/loadDataIfNecessary';
import {
  APP_PATH_DIENSTSTELLE,
  APP_PATH_DIENSTSTELLEN,
  APP_PATH_LEISTUNG,
  APP_PATH_START,
  isTypeFilter,
  REGSCHL_HESSEN,
  REGSCHL_PREFIX_HESSEN,
  SEARCH_FILTER_ALL_TYPES,
  SELECTED_ORT_HESSEN,
  TypeFilter,
} from '../constants';
import type { State } from '../state/createInitialState';
import {
  getAppPathBereich,
  getBereichCode,
  PV_LAGE_BUERGER,
} from '../util/pvLagenUtil';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import {
  getScreenFromPath,
  PARAM_PV_LAGE,
  PARAM_SEARCH_TERM,
  Screen,
} from '../view';
import nullToUndefined from '../view/util/nullToUndefined';
import { flagsToObject, flagsToString } from './flags';
import type { MyHistoryImplType, URLPathSearchParams } from './myHistoryApi';
import { highlightCurrentMenu } from '../view/render/highlightCurrentMenu';
import { deepEquals } from '../util/deepEquals';
import {
  isRegschlFilterType,
  isRegschlLevel,
  RegschlFilterType,
  RegschlLevel,
} from '../action/search/searchTypes';
import { reduceForScreenChange } from '../reducer/reduceForScreenChange';
import type { HtmlPatch } from '../action/htmlTypes';
import type { ServerData } from '../render/cached-incremental-dom';

const PARAM_BEREICH = 'bereich';
const PARAM_CSS_SOURCE_LINK = 'css_source';
export const PARAM_FLAGS = 'flags';
const PARAM_LEISTUNG_ID = 'leistung_id';
const PARAM_ORG_ID = 'org_id';
const PARAM_REGSCHL = 'regschl';
const PARAM_REGSCHL_FILTER = 'regschl_filter';
const PARAM_REGSCHL_WAHL = 'regschl_wahl';
const PARAM_TYPE = 'typ';

function handleUrlChanged(state: State): void {
  if (state.appWasUpdated) {
    console.log('Application updated -> URL changed -> reloading');
    window.location.reload();
  }
  highlightCurrentMenu();
}

function hessenToUndefined(regschl: string | undefined): string | undefined {
  if (regschl === REGSCHL_PREFIX_HESSEN || regschl === REGSCHL_HESSEN) {
    return undefined;
  } else {
    return regschl;
  }
}

export function stateToUrl(state: State): URLPathSearchParams {
  const { screen } = state;

  let params: URLSearchParams;
  let path: string;
  switch (screen) {
    case Screen.Leistung: {
      params = searchParamsLeistung(state);
      path = APP_PATH_LEISTUNG();
      break;
    }
    case Screen.Org: {
      params = searchParamsOrg(state.org.request);
      path = APP_PATH_DIENSTSTELLE();
      break;
    }
    case Screen.Bereich:
    case Screen.Lage:
    case Screen.Sublage:
    case Screen.Suche: {
      params = searchParamsSearch(state);
      path = getAppPathBereich(state.search.request.filter.pvLage);
      break;
    }
    case Screen.OrgSuche: {
      const searchParams = new URLSearchParams();
      const { regschlFilterTypes } = state.search.request;
      let regschlFilterParam: (RegschlLevel | RegschlFilterType)[] | true;
      if (regschlFilterTypes === undefined) {
        regschlFilterParam = [];
      } else if (regschlFilterTypes.length === 0) {
        regschlFilterParam = true;
      } else {
        regschlFilterParam = regschlFilterTypes;
      }
      setUrlParams(searchParams, {
        [PARAM_REGSCHL_FILTER]: regschlFilterParam,
        [PARAM_REGSCHL]: hessenToUndefined(state.citySearch.selectedOrt.id),
        [PARAM_SEARCH_TERM]: state.search.request.searchTerm,
      });
      params = searchParams;
      path = APP_PATH_DIENSTSTELLEN();
      break;
    }
    case Screen.Startseite: {
      params = searchParamsStart(state);
      path = APP_PATH_START();
      break;
    }
    case Screen.Information:
      params = new URLSearchParams(state.information.search);
      path = state.information.path;
      break;
    default:
      throw new UnreachableCaseError(screen);
  }
  if (state.cssSourceLink) {
    params.set(PARAM_CSS_SOURCE_LINK, '');
  }
  const flags = flagsToString(state.flags);
  if (flags) {
    params.set(PARAM_FLAGS, flags);
  }
  return { params, path };
}

function setSelectedOrt(draft: Draft<State>, regschl: string | undefined) {
  if (draft.citySearch.selectedOrt.id !== regschl) {
    // eslint-disable-next-line no-param-reassign
    draft.citySearch.selectedOrt = regschl
      ? {
          loadNeeded: true,
          id: regschl,
          ort: undefined,
          verband: undefined,
          kreis: undefined,
          bezirk: undefined,
          land: '',
          isFindable: false,
        }
      : SELECTED_ORT_HESSEN;
  }
}

function urlToState(
  state1: State,
  urlPathSearchParams: URLPathSearchParams,
  serverData?: {
    patch: HtmlPatch;
    data?: ServerData;
  },
): State {
  return produce(state1, (draft) => {
    /* eslint-disable no-param-reassign */
    const { params, path } = urlPathSearchParams;

    const screen =
      serverData && 'data' in serverData.patch
        ? serverData.patch.data.screen
        : getScreenFromPath(path, params);
    draft.screen = screen;
    reduceForScreenChange(draft);

    const cssSourceLink = params.has(PARAM_CSS_SOURCE_LINK);
    if (draft.cssSourceLink !== cssSourceLink) {
      draft.cssSourceLink = cssSourceLink;
    }
    switch (screen) {
      case Screen.Information: {
        draft.information = {
          path,
          search: params.toString(),
        };
        break;
      }
      case Screen.Leistung: {
        const { leistung } = draft;
        const { request } = leistung;
        const alternateLeistungId =
          serverData &&
          'data' in serverData.patch &&
          serverData.patch.data.screen === Screen.Leistung
            ? serverData.patch.data.leistung.id
            : undefined;
        const leistungParam =
          alternateLeistungId ?? nullToUndefined(params.get(PARAM_LEISTUNG_ID));
        const regschl = hessenToUndefined(
          nullToUndefined(params.get(PARAM_REGSCHL)),
        );
        if (leistungParam && request?.leistung_id !== leistungParam) {
          draft.leistung.request = {
            leistung_id: leistungParam,
          };
        }
        setSelectedOrt(draft, regschl);
        break;
      }
      case Screen.Org: {
        const { org } = draft;
        const { request } = org;
        const orgParam = nullToUndefined(params.get(PARAM_ORG_ID));
        if (orgParam && request?.org_id !== orgParam) {
          draft.org.request = { org_id: orgParam };
        }
        break;
      }
      case Screen.Bereich:
      case Screen.Lage:
      case Screen.Sublage:
      case Screen.Suche: {
        const { search: searchState } = draft;
        const { request } = searchState;

        const pvLageParam = nullToUndefined(params.get(PARAM_PV_LAGE));
        if (pvLageParam && request.filter.pvLage !== pvLageParam) {
          draft.search.request.filter.pvLage = pvLageParam;
        }
        const regschl = hessenToUndefined(
          nullToUndefined(params.get(PARAM_REGSCHL)),
        );
        setSelectedOrt(draft, regschl);
        const searchParam =
          nullToUndefined(params.get(PARAM_SEARCH_TERM)) ?? '';
        if (request.searchTerm !== searchParam) {
          draft.search.request.searchTerm = searchParam;
        }
        const typeParam = nullToUndefined(params.get(PARAM_TYPE));
        draft.search.request.filter.type = (typeParam || '')
          .split(',')
          .filter((value) => isTypeFilter(value)) as TypeFilter[];
        const regschlWahl = nullToUndefined(params.get(PARAM_REGSCHL_WAHL));
        if (request.regschlWahl !== regschlWahl) {
          draft.search.request.regschlWahl = regschlWahl;
          setSelectedOrt(draft, regschlWahl);
        }
        break;
      }
      case Screen.OrgSuche: {
        const { search: searchState } = draft;
        const { request } = searchState;
        const searchParam =
          nullToUndefined(params.get(PARAM_SEARCH_TERM)) ?? '';
        if (request.searchTerm !== searchParam) {
          draft.search.request.searchTerm = searchParam;
        }
        const regschl = hessenToUndefined(
          nullToUndefined(params.get(PARAM_REGSCHL)),
        );
        setSelectedOrt(draft, regschl);
        const regschlFilterParam =
          params.get(PARAM_REGSCHL_FILTER) ?? undefined;
        let regschlFilterTypes:
          | (RegschlLevel | RegschlFilterType)[]
          | undefined;
        if (regschlFilterParam === undefined) {
          regschlFilterTypes = undefined;
        } else if (regschlFilterParam === '') {
          regschlFilterTypes = [];
        } else {
          regschlFilterTypes = regschlFilterParam
            .split(',')
            .filter(
              (value) => isRegschlLevel(value) || isRegschlFilterType(value),
            ) as (RegschlLevel | RegschlFilterType)[];
        }
        regschlFilterTypes?.sort();
        if (
          !deepEquals(
            regschlFilterTypes,
            draft.search.request.regschlFilterTypes,
          )
        ) {
          draft.search.request.regschlFilterTypes = regschlFilterTypes;
        }

        break;
      }
      case Screen.Startseite: {
        const { search: searchState } = draft;
        const { request } = searchState;
        const searchParam =
          nullToUndefined(params.get(PARAM_SEARCH_TERM)) ?? '';
        if (request.searchTerm !== searchParam) {
          draft.search.request.searchTerm = searchParam;
        }
        const pvLageParam =
          nullToUndefined(params.get(PARAM_BEREICH)) ?? PV_LAGE_BUERGER;
        if (pvLageParam && request.filter.pvLage !== pvLageParam) {
          draft.search.request.filter.pvLage = getBereichCode(pvLageParam);
        }
        const regschl = hessenToUndefined(
          nullToUndefined(params.get(PARAM_REGSCHL)),
        );
        setSelectedOrt(draft, regschl);
        break;
      }
      default:
        throw new UnreachableCaseError(screen);
    }
    if (serverData?.data) {
      if (serverData.data.data.pvLagenByCode) {
        draft.pvLagen.pvLagenByCode = serverData.data.data.pvLagenByCode;
      }
      if (
        serverData.data.data.pvLagenCounts &&
        serverData.data.params.regschl
      ) {
        draft.pvLagen.countsByRegschlAndCode[serverData.data.params.regschl] =
          serverData.data.data.pvLagenCounts;
      }
    }
    draft.flags = flagsToObject(nullToUndefined(params.get(PARAM_FLAGS)));
  });
}

function searchParamsLeistung(state: State): URLSearchParams {
  const leistungId = state.leistung.request?.leistung_id;
  const regschl = hessenToUndefined(state.citySearch.selectedOrt.id);

  const searchParams = new URLSearchParams();
  if (leistungId) {
    searchParams.set(PARAM_LEISTUNG_ID, leistungId);
  }
  if (regschl) {
    searchParams.set(PARAM_REGSCHL, regschl);
  }
  return searchParams;
}

function searchParamsOrg(request: undefined | OrgRequest): URLSearchParams {
  const searchParams = new URLSearchParams();
  if (request?.org_id) {
    searchParams.set(PARAM_ORG_ID, request.org_id);
  }
  return searchParams;
}

function searchParamsSearch(state: State): URLSearchParams {
  const {
    filter: { pvLage, type },
    searchTerm,
    regschlWahl,
  } = state.search.request;
  const regschl = state.citySearch.selectedOrt.id;
  const searchParams = new URLSearchParams();
  setUrlParams(searchParams, {
    [PARAM_PV_LAGE]: pvLage,
    [PARAM_REGSCHL]: hessenToUndefined(regschl),
    [PARAM_SEARCH_TERM]: searchTerm,
    [PARAM_TYPE]: !SEARCH_FILTER_ALL_TYPES.every((t) => type.includes(t))
      ? type.join(',')
      : undefined,
    [PARAM_REGSCHL_WAHL]: regschlWahl,
  });
  return searchParams;
}

function searchParamsStart(state: State): URLSearchParams {
  const { searchTerm } = state.search.request;
  const regschl = state.citySearch.selectedOrt.id;
  const { pvLage } = state.search.request.filter;
  const searchParams = new URLSearchParams();
  const bereichCode = getBereichCode(pvLage);
  const bereichParam =
    bereichCode !== PV_LAGE_BUERGER ? bereichCode : undefined;
  setUrlParams(searchParams, {
    [PARAM_REGSCHL]: hessenToUndefined(regschl),
    [PARAM_SEARCH_TERM]: searchTerm,
    [PARAM_BEREICH]: bereichParam,
  });
  return searchParams;
}

function setUrlParams(
  urlSearchParams: URLSearchParams,
  keyValue: Partial<Record<string, undefined | string | string[] | boolean>>,
) {
  Object.entries(keyValue).forEach(([key, value]) => {
    if (value === undefined) {
      // don't add
    } else if (typeof value === 'string') {
      if (value) {
        urlSearchParams.set(key, value);
      }
    } else if (Array.isArray(value)) {
      if (value.length) {
        urlSearchParams.set(key, value.join(','));
      }
    } else if (typeof value === 'boolean') {
      if (value) {
        urlSearchParams.set(key, '');
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown param type: ${value}`);
    }
  });
}

const myHistoryImpl: MyHistoryImplType = {
  handleUrlChanged,
  loadData: loadDataIfNecessary,
  stateToUrl,
  urlToState,
};

export default myHistoryImpl;
