// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LAND_HESSEN } from './texts';
import type { ServerData } from './render/cached-incremental-dom';
import type { ScreenData } from './action/common/ScreenData';

type HzdActionFeedback = 'feedback';

declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    hzd_og_data?: {
      drupalTitle?: string;
      odMapDomIds: string[];
      screenData: ScreenData;
      serverData?: ServerData;
      showChatbot: boolean;
    };
    // eslint-disable-next-line camelcase
    hzd_og_flags?: string;
    // eslint-disable-next-line camelcase
    hzd_og_getLogger?: (scope: string) => (event: Event) => void;

    // Für lokale Entwicklung: App soll durch index_serve gestartet werden
    // eslint-disable-next-line camelcase
    hzd_og_init_defer?: boolean;

    // Für lokale Entwicklung: index speichert hier die Initialisierungsfunktion für den späteren Aufruf durch index_serve
    // eslint-disable-next-line camelcase
    hzd_og_init_start_app?: () => Promise<void>;

    // eslint-disable-next-line camelcase
    hzd_og_settings?: ServerSettings;
    // eslint-disable-next-line camelcase
    hzd_og_srv_root?: string;
    // eslint-disable-next-line camelcase
    hzd_og_sw_url?: string;
    // eslint-disable-next-line camelcase
    jsHandover?: () => {
      pendingActions: (
        | { key: HzdActionFeedback; dom: HTMLButtonElement }
        | { key: 'navigate'; href: string }
        | { key: 'search' }
      )[];
      inputs: NodeListOf<HTMLInputElement>;
    };
    removeOverlay?: () => void;

    // Feedback-Button
    bmi115WidgetButton: { init: (dom: HTMLButtonElement) => void };

    // for unregistering global listeners
    vwpGlobalUnregistration?: (() => void)[];
  }

  /* Workaround für Zugriff auf namespaceURI in incremental-dom */
  interface Node {
    namespaceURI?: string | null;
  }
}

export const FRONTEND_ORIGIN = (): string => window.location.origin;
/** endet mit / */
export const SERVER_ROOT = (): string => window.hzd_og_srv_root ?? '/';

export type ChatbotScriptSrc = { src: string };
export type NfkScriptSrc = { id: string; src: string };
export type NfkSettings = Partial<Record<string, string>>;

export type ServerSettings = {
  chatbotScriptSrc?: ChatbotScriptSrc;
  chatbotBodySafeHtml?: string;
  chatbotOuterSelector?: string;
  chatbotInnerSelector?: string;
  nfkScriptSrc?: NfkScriptSrc;
  nfkSettings: NfkSettings;
};

export const SERVER_SETTINGS = (): ServerSettings =>
  window.hzd_og_settings ?? { nfkSettings: {} };
export const SW_URL = (): string | undefined => window.hzd_og_sw_url;

export const DATA_LIST_MARKER = '\u200b';

export const DOM_ID_SECTION_SHARE = 'og-section-share';

export const DOM_ID_SECTION_SEITENINHALT = 'og-section-seiteninhalt';
export const DOM_ID_DIV_FILTER = 'og-div-filter';

export const REGSCHL_HESSEN = '060000000000';
export const REGSCHL_PREFIX_HESSEN = '06';

export const SELECTED_ORT_HESSEN = {
  loadNeeded: false,
  id: REGSCHL_HESSEN,
  ort: undefined,
  verband: undefined,
  kreis: undefined,
  bezirk: undefined,
  land: LAND_HESSEN,
  isFindable: false,
};

// Millisekunden ohne Eingabe bevor Suchvorschläge vom Server angefragt werden
export const SEARCH_DELAY = 200;
// Verhältnis des Suchergebnisbereichs zur Fensterhöhe, ab dem bei der Suche zu den Ergebnissen gescrollt werden soll
export const SEARCH_RESULTS_SCROLL_HEIGHT_RATIO = 0.4;

export enum TypeFilter {
  info = 'info',
  leistung = 'leistung',
  orgeinheit = 'dienststelle',
}
export function isTypeFilter(value: string): value is TypeFilter {
  return Object.values(TypeFilter).includes(value as TypeFilter);
}
export const SEARCH_FILTER_ALL_TYPES = Object.values(TypeFilter);

export const APP_PATH_START = (): string => SERVER_ROOT();
export const APP_PATH_BEREICH_BUERGER = (): string => `${SERVER_ROOT()}buerger`;
export const APP_PATH_BEREICH_UNTERNEHMEN = (): string =>
  `${SERVER_ROOT()}unternehmen`;
export const APP_PATH_DIENSTSTELLEN = (): string =>
  `${SERVER_ROOT()}behoerdensuche`;
export const APP_PATH_LEISTUNG = (): string => `${SERVER_ROOT()}leistung`;
export const APP_PATH_DIENSTSTELLE = (): string => `${SERVER_ROOT()}behoerde`;

export const SERVER_URL_ERROR_STATISTIC = (): string =>
  `${SERVER_ROOT()}log_error`;
export const SERVER_URL_IMAGE = (): string => `${SERVER_ROOT()}image`;
export const SERVER_URL_LEISTUNG = APP_PATH_LEISTUNG;
export const SERVER_URL_LEISTUNG_SUCHE_STATISTIC = (): string =>
  `${SERVER_ROOT()}leistungsdetails_suche`;
export const SERVER_URL_MAP_ONLINE_COUNTS = (): string =>
  `${SERVER_ROOT()}online_services_by_regschl`;
export const SERVER_URL_MAP_ONLINE_DATA = (): string =>
  `${SERVER_ROOT()}hessen_map_regions.json`;
export const SERVER_URL_ORGEINHEIT = APP_PATH_DIENSTSTELLE;
export const SERVER_URL_ORGEINHEIT_SUCHE_STATISTIC = (): string =>
  `${SERVER_ROOT()}orgdetails_suche`;
export const SERVER_URL_SHARE_STATISTIC = (): string => `${SERVER_ROOT()}share`;
export const SERVER_URL_STARTSEITE = (): string => SERVER_ROOT();
export const SERVER_URL_SUCHE = (): string => `${SERVER_ROOT()}suche`;
export const SERVER_URL_SUCHE_ORG = (): string => `${SERVER_ROOT()}suche_org`;
export const SERVER_URL_SUCHE_MEHR = (): string => `${SERVER_ROOT()}suche_mehr`;
export const SERVER_URL_SUCHE_ORT = (): string => `${SERVER_ROOT()}suche_ort`;
export const SERVER_URL_SUCHE_KEIN_ERGEBNIS_STATISTIC = (): string =>
  `${SERVER_ROOT()}suche_kein_ergebnis`;
export const SERVER_URL_SUCHE_ORT_VORSCHLAG_AUSWAHL_STATISTIC = (): string =>
  `${SERVER_ROOT()}suche_ort_vorschlag_auswahl`;
export const SERVER_URL_SUCHE_ORT_AUSWAHL_STATISTIC = (): string =>
  `${SERVER_ROOT()}suche_ort_auswahl`;
export const SERVER_URL_SUCHE_REGSCHL = (): string =>
  `${SERVER_ROOT()}suche_regschl`;
export const SERVER_URL_SUCHE_VORSCHLAG = (): string =>
  `${SERVER_ROOT()}suche_vorschlag`;
export const SERVER_URL_SUCHE_ORG_VORSCHLAG = (): string =>
  `${SERVER_ROOT()}suche_org_vorschlag`;
export const SERVER_URL_SUCHE_VORSCHLAG_AUSWAHL_STATISTIC = (): string =>
  `${SERVER_ROOT()}suche_vorschlag_auswahl`;
export const SERVER_URL_SUCHE_ORG_VORSCHLAG_AUSWAHL_STATISTIC = (): string =>
  `${SERVER_ROOT()}suche_org_vorschlag_auswahl`;
