import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { getAppPathBereich } from '../../util/pvLagenUtil';
import { scrollToTop } from '../../util/scrollIntoView';
import { isAbortError, isErrorWithStatus, postNoRedirect } from '../util/fetch';
import {
  LOAD_BEREICHSSEITE_ERROR,
  LOAD_BEREICHSSEITE_FINISHED,
  LOAD_BEREICHSSEITE_STARTED,
} from '../../reducer/filterEvents';
import type { BereichRequest } from './PvLagenRequest';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';
import type { HtmlPageBereich } from '../htmlTypes';
import { Screen } from '../../view';
import { handleAdditionalDataLoaded } from './handleAdditionalDataLoaded';

export function loadBereichsseiteIfNotLoading(
  simpleStore: SimpleStore,
  request: BereichRequest,
): void {
  const state = simpleStore.getState();

  const currentLoadingForRequest = state.abortRequest.bereichsseite?.forRequest;
  if (!deepEquals(request, currentLoadingForRequest)) {
    const { abortRequest, result } = postNoRedirect<
      BereichRequest,
      HtmlPageBereich
    >({
      url: getAppPathBereich(request.pv_lage),
      data: request,
      flags: state.flags,
    });
    abortRequestFor(state.abortRequest.bereichsseite);
    simpleStore.dispatch(
      LOAD_BEREICHSSEITE_STARTED({
        abortRequest,
        forRequest: request,
      }),
    );
    result
      .then((pageResult) => {
        const latestRequest =
          simpleStore.getState().abortRequest.bereichsseite?.forRequest;
        if (deepEquals(latestRequest, request)) {
          simpleStore.dispatch(
            LOAD_BEREICHSSEITE_FINISHED({
              patch: pageResult.page,
              screenRequest: {
                screen: Screen.Bereich,
                forRequest: { pv_lage: request.pv_lage },
              },
            }),
          );
          scrollToTop();
        }
        handleAdditionalDataLoaded(
          simpleStore,
          request.regschl,
          pageResult.additionalData,
        );
      })
      .catch((e: Error) => {
        const latestRequest =
          simpleStore.getState().abortRequest.bereichsseite?.forRequest;
        if (deepEquals(latestRequest, request)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(
              LOAD_BEREICHSSEITE_ERROR(new Error('Nicht gefunden')),
            );
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(LOAD_BEREICHSSEITE_ERROR(e));
          }
        }
      });
  }
}
