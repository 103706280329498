import { handleTypeChanged } from '../../action/filter/handleTypeChanged';
import { TypeFilter } from '../../constants';
import type { RenderResultWithSsr } from '../../lib/renderTypes';
import {
  div,
  fieldset,
  i,
  input,
  label,
  legend,
  section,
  span,
} from '../../render/html';
import type { State } from '../../state/createInitialState';
import { ERROR_REGSCHL, TYPE_FILTER_LABEL } from '../../texts';
import { mapBooleanAttributes } from '../../util/mapBooleanAttributes';
import { extractTypFilterCounts } from '../read/extractTypFilterCounts';
import { cityField, createDefaultCityHandlers } from './citySearch';
import error from './error';
import './filter.css';
import { renderResultsError } from './results';
import { selectParamsForCitySearch } from '../read/selectParamsForCitySearch';
import { SearchResultType } from '../../action/search/searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';

export function filter(
  key: string,
  classNames: string,
  simpleStore: SimpleStore,
): RenderResultWithSsr {
  const state = simpleStore.getState();
  const { search: searchState } = state;
  const { filter: activeFilters } = searchState.request;

  let errorDescription: RenderResultWithSsr | undefined;
  if (state.error.regschlSearch) {
    errorDescription = error(ERROR_REGSCHL);
  } else if (state.error.search) {
    errorDescription = renderResultsError(state.error.search);
  }
  const searchResultLoading = Boolean(state.abortRequest.search);

  const searchResults =
    state.search.results.data?.type === SearchResultType.RESULT
      ? state.search.results.data.result
      : undefined;

  const {
    dropdownState,
    inputValue,
    loading,
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  } = selectParamsForCitySearch(state);

  const cityDiv = cityField({
    dropdownState,
    inputValue,
    loading,
    handlers: createDefaultCityHandlers({
      simpleStoreForHandlers: simpleStore,
    }),
    ariaDescription,
    placeholder,
    showFeedbackHint,
    suggestions,
  });
  const typeDiv =
    searchResults || searchResultLoading || activeFilters.type.length > 0
      ? renderTypes(state, simpleStore)
      : undefined;

  return section({ key, 'aria-label': 'Suchfilter', class: classNames }, [
    cityDiv,
    typeDiv,
    errorDescription,
  ]);
}

function renderTypes(state: State, simpleStore: SimpleStore) {
  const { counts, loading } = extractTypFilterCounts(state);
  const boxes = [
    {
      id: 'checkbox-type-leist',
      label: TYPE_FILTER_LABEL(TypeFilter.leistung),
      value: TypeFilter.leistung,
      iconClass: 'bi-list-ul',
      count: counts.leistungen,
    },
    {
      id: 'checkbox-type-org',
      label: TYPE_FILTER_LABEL(TypeFilter.orgeinheit),
      value: TypeFilter.orgeinheit,
      iconClass: 'bi-buildings',
      count: counts.orgeinheiten,
    },
    {
      id: 'checkbox-type-info',
      label: TYPE_FILTER_LABEL(TypeFilter.info),
      value: TypeFilter.info,
      iconClass: 'bi-info-square',
      count: counts.editorialPages,
    },
  ];
  const onchange = (inputId: string) => () => {
    handleTypeChanged(simpleStore, boxes, inputId);
  };

  const activeTypeFilters = state.search.request.filter.type;

  return fieldset({ class: 'layout-label-field og-type-fieldset' }, [
    legend({}, 'Filter'),
    div({ class: 'layout-label-field__field row og-layout-types' }, [
      ...boxes.map(({ id, label: text, value, iconClass, count }, index) =>
        renderTypCheckbox(
          id,
          text,
          value,
          activeTypeFilters.includes(value),
          onchange(id),
          index,
          iconClass,
          count,
          loading,
        ),
      ),
    ]),
  ]);
}

function renderTypCheckbox(
  inputId: string,
  text: string,
  value: string,
  checked: boolean,
  onchange: (event: InputEvent) => void,
  index: number,
  iconClass: string,
  count: number | undefined,
  loading: boolean,
) {
  // eslint-disable-next-line no-nested-ternary
  const countString = loading ? '...' : count === undefined ? '' : `(${count})`;
  return div(
    {
      class: `og-input-type col-lg-4`,
    },
    [
      input({
        ...mapBooleanAttributes({
          autocomplete: 'off',
          class: `layout-label-field__field og-input-type__field`,
          id: inputId,
          name: 'filter-type',
          type: 'checkbox',
          value,
          onchange,
          checked,
        }),
        // wichtig: der Wert, den der Nutzer durch anklicken ändert, ist nicht das Attribut "checked", sondern die
        // property "checked". Daher muss hier sowohl das Attribut (siehe oben), als auch die Property geändert werden
        'prop-checked': checked,
      }),
      label(
        {
          class: `layout-label-field__label og-input-type__label${
            count === 0 ? ' og-color--inactive' : ''
          }`,
          for: inputId,
        },
        [
          i({
            class: `bi ${iconClass} og-type-icon icon og-type-icon-${value}${
              count === 0 ? ' og-fill--inactive' : ''
            }`,
          }),
          span({ class: 'flex-grow-1' }, `${text} ${countString}`),
        ],
      ),
    ],
  );
}
