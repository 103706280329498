import { SERVER_URL_STARTSEITE } from '../../constants';
import { logError } from '../../util/logError';
import { scrollToAnchor } from '../../util/scrollIntoView';
import type { HtmlPageStartseite } from '../htmlTypes';
import { isAbortError, postNoRedirect } from '../util/fetch';
import {
  STARTSEITE_ERROR,
  STARTSEITE_FINISHED,
  STARTSEITE_STARTED,
} from './detailEvents';
import type { StartseiteRequest } from './detailTypes';
import type { SimpleStore } from '../../state/SimpleStore';

export function loadStartseiteIfNotLoading(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  if (state.abortRequest.startseite) {
    return;
  }

  const { abortRequest, result } = postNoRedirect<
    StartseiteRequest,
    HtmlPageStartseite
  >({
    url: SERVER_URL_STARTSEITE(),
    data: {},
    flags: state.flags,
  });

  simpleStore.dispatch(STARTSEITE_STARTED(abortRequest));
  result
    .then((pageResult: HtmlPageStartseite) => {
      simpleStore.dispatch(STARTSEITE_FINISHED(pageResult.page));
      scrollToAnchor(simpleStore);
    })
    .catch((e: Error) => {
      // request war nicht erfolgreich
      if (isAbortError(e)) {
        // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
      } else {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(STARTSEITE_ERROR(e));
      }
    });
}
